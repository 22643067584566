@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* src/css/style.css */
@font-face {
  font-family: 'Nacelle';
  src: url('/public/fonts/nacelle-regular.woff2') format('woff2'),
       url('/public/fonts/nacelle-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nacelle';
  src: url('/public/fonts/nacelle-semibold.woff2') format('woff2'),
       url('/public/fonts/nacelle-semibolditalic.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

